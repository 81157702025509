<template>
    <div class="body">
      
        <div id="home">
            <MyComponent />
            <img class="logo" src="../assets/logo.jpg" alt="Logo">

        </div>
    </div>
</template>

<script>
    import MyComponent from '../components/MyComponent.vue';

    export default {
        name: 'App',

        components: {
            MyComponent,
        },

        mounted() {
            document.title = 'Cook Coding';
        },
    };
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 0px;
        background-color: #2F3640;
    }

    .img {
        width: 50%;
        height: auto;
        margin-bottom: 0px;
    }

    .logo {
        display: block;
        margin: 0 auto;
        max-width: 50%;
    }

    nav {
        background-color: grey;
        overflow: hidden;
    }

        nav ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        nav li {
            float: left;
        }

            nav li a {
                display: block;
                color: white;
                text-align: center;
                padding: 14px 16px;
                text-decoration: none;
            }

                nav li a:hover {
                    background-color: #111;
                }
    img {
        width: 40%;
    }

</style>
