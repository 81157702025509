
<template>
    <div>
        <div class="body">

            <div id="projects">
                <MyComponent />
                <br />
                <h2>Current Projects</h2>
                <p>Theology Treasury - Sermon Catalog &Aacute; database.</p>
                <p>Website for Mental Health Counseling Center <a href="https://www.replenishcounselingllc.com" target="_blank">Replenish Counseling</a></p>
                <p>All Other current Projects are protected by Non-Disclosure Agreements.</p>
                <br />
                <h2>Past Projects</h2>
                <p><b>Message Transcriber</b> (Developed for <a href="https://mywaterstone.church" target="_blank">Waterstone Church</a>):</p>
                <p>Transcribes audio from selected .mp4 file to a Word Document. Requires Microsoft Azure subscription.</p>

                <br />
                <p><b>Achevee360</b>: Production management system used in large scale bakery</p>
            </div>
        </div>
    </div>
</template>

<script>
    import MyComponent from '../components/MyComponent.vue';

    export default {
        name: 'Projects',
        components: {
            MyComponent,
        },

        mounted() {
            document.title = 'Cook Coding - Projects';
        },
    };
</script>

<style src="../style.css"></style>
