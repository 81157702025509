<template>
    <div>
        <div class="body">
            <div id="downloads">
                <MyComponent />
                <h2>Downloads</h2>
                <a href="/assets/files/messagetranscriber.zip" download>Message Transcriber</a>
            </div>
        </div>
    </div>
</template>

<script>
    import MyComponent from "../components/MyComponent.vue";

    export default {
        name: "Downloads",
        components: {
            MyComponent,
        },

        mounted() {
            document.title = 'Cook Coding - Downloads';
        },
    };
</script>

<style>
    .file {
        margin-bottom: 10px;
    }
</style>
