
<template>
    <div>
        <div class="body">

            <div id="contact">
                <MyComponent />
                <br />
                <h2>Available Services</h2>
                <br />          
                <p>Web Design</p>
                <p>Custom Windows Applications</p>
                <p>Mobile App Development</p>
                <p>E-commerce Solutions</p>
            </div>
        </div>
    </div>
</template>

<script>
    import MyComponent from '../components/MyComponent.vue';

    export default {
        name: 'Contact',
        components: {
            MyComponent,
        },

        mounted() {
            document.title = 'Cook Coding - Contact';
        },
    };
</script>

<style src="../style.css"></style>
