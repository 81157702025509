import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Projects from '@/views/Projects.vue';
import Downloads from '@/views/Downloads.vue';
import About from '@/views/About.vue';
import Contact from '@/views/Contact.vue';



const routes = [
    { path: '/', name: 'Home',  component: Home },
    { path: '/about', name: 'About', component: About },
    { path: '/contact', name: 'Contact', component: Contact },
    { path: '/downloads', name: 'Downloads', component: Downloads },
    { path: '/projects', name: 'Projects', component: Projects }
];

const router = createRouter({
    history: createWebHistory(),
    routes: [{ path: '/', name: 'Home', component: Home },
        { path: '/about', name: 'About', component: About },
        { path: '/contact', name: 'Contact', component: Contact },
        { path: '/downloads', name: 'Downloads', component: Downloads },
        { path: '/projects', name: 'Projects', component: Projects }],
});

export default router;
