<template>
    <div class="body">
        <Navbar />
        <div id="base">
            <router-view />
        </div>
    </div>
</template>

<script>
    import Navbar from "./components/Navbar.vue";

    export default {
        name: "App",
        components: {
            Navbar,
        },
    };
</script>

<style src="./style.css"></style>