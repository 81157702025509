<style src="../style.css"></style>

<template>
    <div class="my-component">
        <h1 class="title">Cook Coding</h1>
        <p class="description">Custom made solutions for the digital world.</p>
    </div>
</template>


